import React from 'react'
import { Link } from 'gatsby'

const ResultsSidenav = ({data}) => {
  return (
    <div className="sidenav">
      <Link to="/results/" activeClassName="active">Latest results</Link>
      {/* <Link to="/results/demos/" activeClassName="active">Demonstrations</Link> */} 
      <Link to="/results/publications/" activeClassName="active">Articles</Link>
      <Link to="/results/conference_papers/" activeClassName="active">Conference papers</Link>
      <Link to="/results/demonstrations/" activeClassName="active">Demos and Software</Link>
      <Link to="/results/presentations/" activeClassName="active">Presentations</Link>
      <Link to="/results/media/" activeClassName="active">In media</Link>
    </div>
  )
}
export default ResultsSidenav
